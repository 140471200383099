/* @jsx jsx */
import { PageProps } from "gatsby";
import { jsx } from "theme-ui";
import qs from "querystring";
import { useEffect, useRef, useState } from "react";
import "tailwindcss/dist/base.min.css";

import { FeedbackForm } from "../../../npm/src/FeedbackForm";

function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

let lastHeight: number | null = null;
let lastWidth: number | null = null;

export default function Widget(props: PageProps) {
  const [metadata, setMetadata] = useState<{
    [key: string]: string;
  }>();
  const search =
    typeof window !== `undefined`
      ? window.location.search
      : typeof location !== `undefined`
      ? location.search
      : "";
  const params = qs.parse(search.substring(1));

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      try {
        const data = JSON.parse(e.data);
        if (data.feedbackFishEvent === "metadata") {
          setMetadata(data.metadata);
        }
      } catch (e) {}
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, []);

  useInterval(() => {
    if (!window.parent) return;

    const height = document.body.scrollHeight;
    const width = document.body.scrollWidth;

    if (height === lastHeight && width === lastWidth) return;

    window.parent.postMessage(
      JSON.stringify({
        width,
        height,
      }),
      "*"
    );
    lastHeight = height;
    lastWidth = width;
  }, 50);

  const { userid, ...meta } = metadata || {};
  const lang = Array.isArray(params.lang) ? params.lang[0] : params.lang;
  const whitelabel = Array.isArray(params.whitelabel)
    ? params.whitelabel[0]
    : params.whitelabel;

  return (
    <FeedbackForm
      userId={userid}
      whitelabel={whitelabel === "true"}
      metadata={Object.keys(meta).length !== 0 ? meta : undefined}
      projectId={Array.isArray(params.pid) ? params.pid[0] : params.pid}
      onClose={() => {
        window.parent.postMessage("close", "*");
      }}
      lang={lang}
    />
  );
}
